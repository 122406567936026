.notes_app {
  $bg-color: rgb(241, 245, 249);
  $color-1: rgb(107, 114, 128);
  $color-2: rgb(17, 24, 39);

  .app_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $bg-color;

    h1 {
      margin-bottom: 0;
    }

    .sidebar_trigger {
      margin-right: 0.5rem;
    }
  }

  .app_content {
    position: relative;

    .app_sidebar {
      width: 240px;
      height: 100%;
      background-color: white;
      border-right: 1px solid $border-color;

      .sidebar_content {
        padding: 24px 16px;

        li {
          list-style: none;

          .btn-oval {
            width: 100%;
            text-align: left;
            font-weight: 500;

            svg {
              width: 22px;
              margin-right: 1rem;
              color: rgba(0, 0, 0, 0.26);
            }

            &.active {
              svg {
                color: $secondary;
              }
            }
          }
        }
      }
    }

    .content_left {
      .app_sidebar_overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: none;
        z-index: 1;
      }
    }

    .content_right {
      width: 100%;
      padding: 24px;

      .notes_content {
        width: 100%;
        background-color: $bg-color;
        border: 1px solid $border-color;
        border-radius: 1rem;
        padding: 1.2rem;

        .content_top {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 0.5rem;
          padding-bottom: 1.7rem;

          .create_note_trigger {
            width: 90%;
            max-width: 512px;
            background-color: white;
            border-radius: 1rem;
            font-size: 1.2rem;
            padding: 1.2rem;
            cursor: text;
            box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1);
          }
        }

        .content_bottom {

        }
      }
    }
  }

  .note_card {
    position: relative;
    width: 100%;
    background-color: white;
    padding: 16px 20px;
    border-radius: 24px;
    box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    .cover_image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 164px;
      object-fit: cover;
      border-top-right-radius: 24px;
      border-top-left-radius: 24px;

      & + .title {
        margin-top: calc(164px + 0.5rem);
      }
    }

    .title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .description {
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .tasks {
      list-style: none;
      margin-bottom: 1rem;
      padding-left: 0;

      .task {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          margin-right: 0.5rem;
          color: $color-1;
        }

        &.completed {
          svg {
            color: $secondary;
          }

          span {
            text-decoration: line-through;
            color: $color-1;
          }
        }
      }
    }

    .labels .badge {
      background-color: rgb(233, 237, 241);
      color: $color-1;
      border-radius: 1rem;
      padding-left: 12px;
      padding-right: 12px;
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }

  .edit_screen {
    width: 512px;
    min-height: 240px;
    cursor: inherit;

    .title, .description, .tasks, .labels {
      margin-bottom: 1.5rem;
    }

    .cover_image {
      height: 320px;

      & + .title {
        margin-top: calc(320px + 0.5rem);
      }
    }

    .btn.remove_image {
      position: absolute;
      top: calc(320px - 4rem);
      right: 1.2rem;
      z-index: 1;

      svg {
        color: white;
      }
    }

    .title input {
      font-weight: 600;
    }

    .tasks li {
      margin-bottom: 0.5rem;

      svg {
        color: $color-1;
      }

      &.task_input.completed input {
        text-decoration: line-through;
        color: $color-1;
      }
    }

    .labels {
      margin-bottom: 36px;
      display: flex;
      flex-wrap: wrap;

      .badge {
        display: flex;
        align-items: center;
        padding-right: 6px;
      }

      .btn.remove_label {
        padding: 0;
        margin-left: 0.25rem;
        color: rgba($color-2, 0.25);

        svg {
          width: 20px;
          height: 20px;
          color: inherit;
        }

        &:hover {
          color: inherit;
        }
      }
    }

    .table.note_permissions {
      tr {
        height: auto;
        cursor: inherit;

        svg {
          color: $color-1;
        }

        &:hover {
          background-color: $theme-bg-hover-color;
        }
      }
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn svg {
        width: 20px;
      }

      .dropdown .select_btn {
        display: flex;
        align-items: center;

        svg {
          width: 20px;
          margin-right: 0.5rem;
        }
      }
    }

    .MuiOutlinedInput-root,
    .MuiOutlinedInput-input {
      padding: 2px 0 !important;
    }

    .MuiCheckbox-root {
      padding: 0;
      margin-right: 0.5rem;
    }

    fieldset {
      border: none !important;
    }
  }

  .modal-header {
    background-color: transparent;
    padding-top: 24px;
    padding-bottom: 10px;

    .modal-title {
      color: $body-color !important;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .modal-body {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &.update_screen .modal-content {
    width: max-content;
    background-color: transparent;
  }
}

@include media-breakpoint-down(lg) {
  .notes_app {
    /*.content_right {
      width: 100%;
    }*/

    .app_sidebar {
      transform: translateX(-110%);
      transition: all 0.3s ease-in-out;
      position: absolute;
      z-index: 5;

      &.show {
        transform: translateX(0);
        transition: all 0.3s ease;
        box-shadow: 4px 2px 6px 0 rgba(0, 0, 0, 0.15);

        & + .app_sidebar_overlay {
          display: block;
        }
      }
    }
  }
}

.dark-layout {
  .notes_app {
    $color-1: rgb(107, 114, 128);

    .app_header {
      background-color: $theme-dark-body-bg;
    }

    .app_content {
      .app_sidebar {
        background-color: $theme-dark-paper-bg;
        border-color: #404656;

        li .btn-oval svg {
          color: $color-1;
        }
      }

      .notes_content {
        background-color: $theme-dark-body-bg;
        border-color: #404656;

        .create_note_trigger {
          background-color: $theme-dark-paper-bg !important;
        }
      }
    }

    .note_card {
      background-color: $theme-dark-paper-bg;
    }

    .edit_screen {
      .table.note_permissions tr:hover {
        background-color: $theme-dark-bg-hover-color;
      }
    }

    &.notes_update_labels {
      .modal-title {
        color: white !important;
      }

      svg {
        color: white;
      }
    }
  }
}