.scrum_board_app {
  $bg-color-1: rgb(241, 245, 249);
  $bg-color-2: rgb(246, 249, 251);
  $bg-color-3: rgb(233, 237, 241);
  $color-1: rgb(107, 114, 128);
  $color-2: rgb(17, 24, 39);

  .app_header {
    border-bottom: 1px solid $border-color;
  }

  .app_content {
    padding: 24px 12px;
    background-color: $bg-color-1;
    overflow: hidden;

    .card_list {
      display: flex;
      flex-direction: column;
      width: 320px;
      margin-left: 8px;
      margin-right: 8px;
      padding: 0 1rem;
      background-color: $bg-color-2;
      border: 1px solid $border-color;
      border-radius: 12px;
      // max-height: 100%;

      .card_list_content {
        display: flex;
        flex-direction: column;
        min-height: 100%;
        max-height: 100%;
      }

      .card_list_header {
        position: relative;
        left: -1.2rem;
        width: calc(100% + 2.4rem);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1.2rem 0.2rem 1rem 1.2rem;

        .card_list_title {
          width: 100%;
          padding-right: 0.5rem;
        }

        .dropdown .btn-round {
          position: relative;
          top: -0.75rem;
        }
      }

      .cards_container {
        width: 100%;
        min-height: 100%;


      }

      .item_card {
        background-color: white;
        border-radius: 0.8rem;
        box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.1);
        cursor: pointer;
        margin-bottom: 1rem;

        .card_cover {
          width: 100%;
          border-top-left-radius: 0.8rem;
          border-top-right-radius: 0.8rem;
          // max-height: 215px;
          height: 215px;
          object-fit: cover;
        }

        .card_content {
          padding: 1rem 1rem 0;

          .card_title {
            font-weight: 500;
            margin-bottom: 1rem;
          }
        }

        .card_footer {
          padding: 0.5rem 1rem 1rem;
          color: $color-1;

          .icons_group {
            display: flex;
            align-items: center;
            font-size: 12px;

            .icon_group {
              display: flex;
              align-items: center;
              margin-right: 0.2rem;
              padding: 0.15rem 0.2rem;
              border-radius: 0.4rem;

              &.bg-success {
                color: white;
              }

              svg {
                width: 15px;
                height: 15px;
              }

              span {
                margin-left: 0.15rem;
              }
            }
          }
        }
      }

      .card_list_footer {
        position: relative;
        left: -1.2rem;
        width: calc(100% + 2.4rem);
        padding: 0.5rem 1.2rem;

        .add_btn {
          width: 100%;
          text-align: left;
          color: $color-1;
        }
      }
    }

    .add_list_btn {
      width: 100%;
      height: 100%;
      color: $color-1;
    }
  }

  .add_column {
    min-width: 320px;
    margin-left: 8px;

    .add_column_card {
      width: 100%;
      padding: 1rem;
      border-radius: 0.8rem;
      background-color: $bg-color-3;
    }
  }

  .labels {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .badge {
      background-color: rgb(233, 237, 241);
      color: $color-2;
      border-radius: 1rem;
      padding-left: 12px;
      padding-right: 12px;
      margin-right: 2px;
      margin-bottom: 2px;

      &-edit {
        display: flex;
        align-items: center;
        padding-right: 6px;
      }

      &-icon {
        display: flex;
        align-items: center;
        padding-left: 8px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &.bg-danger,
      &.bg-success {
        color: white;
      }
    }

    .btn.remove_label {
      padding: 0;
      margin-left: 0.25rem;
      color: rgba($color-2, 0.25);

      svg {
        width: 20px;
        height: 20px;
        color: inherit;
      }

      &:hover {
        color: inherit;
      }
    }

    &.members {
      .avatar {
        margin-right: 0.1rem;
      }

      .avatar .avatar-content {
        font-size: 0.65rem;
      }
    }
  }

  &.update_screen {
    max-width: 768px;
  }

  .edit_screen {
    display: flex;
    padding: 0.5rem;

    .content_left {
      padding: 1.2rem;

      .group_title {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 1rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.5rem;
        }
      }

      .labels {
        .badge {
          font-size: 1rem;
          font-weight: normal;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;

          &.members {
            padding-left: 5px;
            padding-right: 4px;

            .avatar .avatar-content {
              font-size: 0.5rem;
            }

            .remove_label svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .tasks {
        list-style: none;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        padding-left: 0;

        li {
          margin-bottom: 0.5rem;

          svg {
            color: $color-1;
          }

          &.task_input {
            &.create {
              svg {
                margin-right: 1.1rem !important;
              }
            }

            &.completed {
              svg {
                color: $secondary;
              }

              input {
                text-decoration: line-through;
                color: $color-1;
              }
            }
          }
        }

        .MuiOutlinedInput-root,
        .MuiOutlinedInput-input {
          padding: 0.2rem 0 0 0.2rem !important;
        }

        .MuiCheckbox-root {
          padding: 0;
          margin-right: 1rem;
        }

        fieldset {
          border: none !important;
        }

        .task {
          display: flex;
          align-items: center;

          svg {
            width: 20px;
            margin-right: 0.5rem;
            color: $color-1;
          }

          &.completed {
            svg {
              color: $secondary;
            }

            span {
              text-decoration: line-through;
              color: $color-1;
            }
          }
        }
      }

      .card_attachment {
        display: flex;
        margin-bottom: 1rem;
        position: relative;
        width: 50%;

        .attachment_name {
          font-size: 1.2rem;
          font-weight: 600;
        }

        .attachment_date,
        .attachment_size {
          color: $color-1;
        }

        .attachment_controls {
          margin-top: 0.5rem;

          .btn {
            font-size: 0.8rem !important;
            width: 120px;
          }

          .remove_button {
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            width: auto;
            display: none;

            svg {
              width: 24px;
              color: white;
            }
          }
        }

        img {
          width: 150px;
          height: 120px;
          border-radius: 0.8rem;
          object-fit: cover;
          margin-right: 1rem;
        }

        &:hover {
          .attachment_controls .remove_button {
            display: flex;
          }
        }
      }

      .card_comments {
        .comment {
          display: flex;
          margin-bottom: 0.5rem;

          .comment_text {
            margin-left: 1rem;
            border: 1px solid $border-color;
            padding: 0.5rem;
            border-radius: 5px 20px 20px 5px;

            .sender {
              display: flex;
              align-items: center;
              font-weight: bold;

              span {
                color: $color-1;
                font-size: 0.85rem;
                font-weight: normal;
                margin-left: 1rem;
              }
            }
          }
        }
      }
    }

    .toolbar_container {
      width: 48px;
      display: flex;
      flex-direction: column;

      .toolbar {
        position: sticky;
        top: 0.5rem;
        background-color: $bg-color-1;
        border-radius: 1rem;

        & > div {
          margin-bottom: 0.45rem;
        }

        .dropstart {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .modal-header {
    background-color: transparent;
    padding-top: 24px;
    padding-bottom: 10px;

    .modal-title {
      color: $body-color !important;
      font-size: 1.5rem;
      font-weight: 600;
      width: 100%;
    }
  }

  .modal-body {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

.dark-layout {
  .scrum_board_app {
    $bg-color-2: rgb(21, 28, 43);
    $bg-color-3: rgb(12, 18, 29);
    $bg-color-4: rgba(white, 0.16);

    .app_header {
      border-color: $theme-dark-border-color;
    }

    .app_content {
      background-color: $theme-dark-body-bg;

      .card_list {
        display: flex;
        background-color: $bg-color-2;
        border-color: $theme-dark-border-color;

        .item_card {
          background-color: $theme-dark-paper-bg;
          box-shadow: 4px 6px 7px 0 rgb(0 0 0 / 25%);
        }

        .card_list_footer .add_btn svg {
          color: inherit;
        }
      }

      .add_column {
        button svg {
          color: inherit;
        }

        .add_column_card {
          background-color: $bg-color-3;
        }
      }
    }

    .labels {
      .badge {
        background-color: $bg-color-4;
        color: white;
      }

      .btn.remove_label {
        color: rgba(white, 0.45);

        &:hover {
          color: white;
        }
      }
    }

    .edit_screen {
      .toolbar {
        background-color: $theme-dark-body-bg;
      }

      .content_left .card_comments .comment_text {
        border-color: $theme-dark-border-color;
      }
    }

    .app_content .card_list .cards_container::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
      background-color: #1e232f !important;
    }
  }
}

.field-error {
  color: red;
  padding: 5px 5px 5px 5px;
}