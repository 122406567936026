.users-online-status {
  border-radius: 1rem;

  .header {
    padding: 24px 24px 12px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .title {
      .title-text {
        font-size: 18px;
        letter-spacing: -0.025em;
        font-weight: 600;
        line-height: 24px;
      }

      .subtitle-text {
        font-size: .857rem;
        font-weight: 400;
        color: #6c757d;
      }
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }

  .chart-container {
    min-height: 350px;

    svg {
      border-radius: 1rem;

      .legend-mouseover-inactive {
        opacity: 0;
      }
    }
  }
}