.stat-card {
  border-radius: 1rem;
  max-height: 500px;
  height: 100%;
  background-color: $theme-nested-card-bg;

  .title {
    p {
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 24px;
      color: rgb(107, 114, 128);
    }
  }

  .contents {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .count {
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -.025em;
    font-weight: 700;
    margin: 0;

    @media screen and (min-width: 600px) {
      font-size: 64px;
      line-height: 64px;
    }
  }

  .subtitle {
    margin-bottom: 0;
    margin-top: .5rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
  }
}

.dark-layout {
  .stat-card {
    background-color: $theme-dark-nested-card-bg;

    .title {
      p {
        color: $theme-muted-text-dark;;
      }
    }
  }
}