.list_view {
  .list_view_content {
    table {
      tr .channel_badge {
        max-width: 160px;
        color: white;
        padding: 0.25rem 0.5rem;
        text-align: center;
        border-radius: 8px;
        text-transform: capitalize;
      }
    }
  }

  .list_view_footer {
    min-height: $footer-height;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.offcanvas.list_view_item_canvas {
  width: 550px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  .offcanvas-header {
    padding: 2rem 0;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $border-color;

    h1 {
      font-size: 28px;
      font-weight: 800;
    }

    .btn-close {
      background-size: 1.2rem;
    }
  }

  .offcanvas-body {
    padding: 0;
    margin-right: -12px;

    .canvas_content {
      padding: 2px 2px 0;
      margin-right: 10px;
    }
  }

  .canvas_footer {
    min-height: 60px;
  }
}

.dark-layout {
  .offcanvas.list_view_item_canvas .offcanvas-header {
    border-color: $theme-dark-border-color;
  }
}