.weekly-contacts-card {

  .header {
    padding-bottom: 12px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .title {


      .title-text {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.025em;
        font-weight: 500;
        color: $theme-muted-text-light;
      }

      .subtitle-text {
        font-size: .857rem;
        font-weight: 400;
        color: #6c757d;
      }
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }

  .chart-container {

    svg {
      border-radius: 1rem;
    }
  }
}