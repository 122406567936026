.donut-chart-card {
  border-radius: 1rem;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: $theme-nested-card-bg;

  .header {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    color: $theme-muted-text-light;

    .title {
      .title-text {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.025em;
        font-weight: 500;
      }

      .subtitle-text {
        font-size: .857rem;
        font-weight: 400;
        color: #6c757d;
      }
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }

  .chart-container {
    justify-self: end;
    margin-top: 20px;
    height: 165px;
    width: 100%;
    max-width: 385px;
  }

  .label {
    color: $theme-muted-text-light;
  }
}

.dark-layout {
  .donut-chart-card {
    background-color: $theme-dark-nested-card-bg;

    .header {
      .title {
        color: $theme-muted-text-dark;
      }
    }

    .label {
      color: $theme-muted-text-dark;
    }
  }
}