.dashboard {
  .welcome-section {
    box-shadow: rgb(226 232 240) 0px 0px 0px 1px inset;
    // padding-left: 10px;
    // padding-right: 10px;

    // @media screen and (min-width: 600px) {
    //   padding-left: 18px;
    //   padding-right: 18px;
    // }
  }

  .content-section {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media screen and (min-width: 600px) {
      margin-top: 3rem;
      margin-bottom: 3rem;
      flex-direction: row;
    }
  }

  .welcome {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    min-width: 0;

    .welcome-message-text {
      font-size: 1.43rem;
      letter-spacing: -.025em;
      font-weight: 600;
      margin: 0;

      @media screen and (min-width: 960px) {
        font-size: 2.575rem;
        line-height: 1.375;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    margin-top: 1.714rem;

    button {
      span {
        margin-left: .5rem;
      }
    }

    @media screen and (min-width: 600px) {
      margin-top: 0;
      margin-left: .571rem;
      margin-right: .571rem;
    }
  }
}