@import './WelcomeSection/WelcomeSection.scss';
@import './components/AreaChartCard/AreaChartCard.scss';
@import './components/DonutChartCard/DonutChartCard.scss';
@import './components/SingleAreaChartCard/SingleAreaChartCard.scss';
@import './components/UserStatusCard/UsersOnlineStatusCard.scss';
@import './components/WeeklyContactsCard/WeeklyContactsCard.scss';
@import './ContactsOverview/ContactsOverview.scss';
@import './Statistics/Statistics.scss';
@import './components/StatCard/StatCard.scss';
@import './UsersOnlineStatus/UsersOnlineStatus.scss';
@import './UsersOnlineStatusTable/UsersOnlineStatusTable.scss';
@import 'WeeklySummary/WeeklySummary';
@import './UsersOnlineStatus/UsersOnlineStatus.scss';
@import './DashboardTabs/DashboardTabs.scss';


// Apex Chart styles
.apexcharts-datalabels .apexcharts-datalabel {
  fill: white !important;
}