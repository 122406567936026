.ck-editor__editable {
  min-height: 200px;
}

.dark-layout {
  .ck-editor {
    color: black;
  }
}

:root {
  --ck-z-default: 2000 !important;
  --ck-border-radius: 4px !important;
}