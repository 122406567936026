.user-online-status-card {
  padding-bottom: 32px;
  border-radius: 16px;

  .user-status {
    padding-right: 16px;
  }

  .user-status.online {
    padding: 2px 4px;
    font-size: 12px;
    color: rgb(67 160 71);
    border: 1px solid rgb(67 160 71) !important;

    .bullet {
      background: rgb(67 160 71) !important;
    }
  }

  .user-status.offline {
    padding: 2px 4px;
    font-size: 12px;
    color: rgb(229 57 53);
    border: 1px solid rgb(229 57 53) !important;

    .bullet {
      background: rgb(229 57 53) !important;
    }
  }

  .username {
    margin-top: 24px;
  }

  .user-designation {
    color: $theme-muted-text-light
  }

  .avatar-content {
    font-size: 28px;
  }
}

.dark-layout {
  .user-online-status-card {
    .username {
      color: white
    }

    .user-designation {
      color: $theme-muted-text-dark
    }
  }
}