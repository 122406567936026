.single-area-chart-card {
  border-radius: 1rem;

  .title-text {
    font-size: 1.43rem;
    font-weight: 500;
    margin: 0;
    color: #5e5873;

    @media screen and (min-width: 600px) {
      font-size: 1.15rem;
    }
  }

  .badge {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .chart-container {
    svg {
      border-radius: 1rem;
    }
  }
}