@import 'ScrumBoard';


.scrum_board_app.scrum_board_app_container {
  width: 100%;
  padding: 1rem 3rem;

  .title {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 800;
    letter-spacing: -0.05rem;
    text-align: center;
    margin-top: 8.5rem;
    margin-bottom: 5.5rem;
  }

  .scrum_boards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1148px;
    padding-right: 0.75rem;

    .board_card {
      min-width: 280px;
      min-height: 360px;
      background-color: white;
      padding: 2rem;
      margin-bottom: 1rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      border-radius: 10px;
      flex: 1;

      &:hover {
        border: 1px solid $border-color;
        box-shadow: 2px 3px 4px 0 rgb(0 0 0 / 10%);
      }

      .board_icon {
        display: inline-block;
        padding: 1.2rem;
        border-radius: 50%;
        background-color: rgb(129, 140, 248);
        color: rgb(55, 48, 163);
      }

      .board_description_container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: calc(100% - 4.5rem);
        cursor: pointer;

        hr {
          margin-top: 2rem;
          width: 30%;
        }
      }

      .board_title {
        margin-top: 1.5rem;
        font-weight: 500;
        font-size: 1.2rem;
      }

      &.create_board {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed $border-color;
        box-shadow: none;
        background-color: transparent;
      }
    }
  }
}

/*Context Menu styles*/
.app-context {
  .react-contexify__item svg {
    margin-right: 0.75rem;
  }

  .react-contexify__separator {
    height: 0.5px;
  }

  &.react-contexify__will-leave--scale {
    animation: contexify-leave-fix 10ms 1;

    @keyframes contexify-leave-fix {
      from {
        opacity: 1
      }
      to {
        opacity: 1
      }
    }
  }
}

.dark-layout {
  .scrum_board_app.scrum_board_app_container {
    .board_card {
      background-color: $theme-dark-paper-bg;
      color: $theme-dark-body-color;

      &:hover {
        border: 1px solid $theme-dark-border-color;
        box-shadow: 4px 6px 7px 0 rgb(0 0 0 / 25%);
      }

      &.create_board {
        border-color: $theme-dark-border-color;
      }
    }
  }
}