.users_status_table {
  table {
    thead tr th,
    tbody tr td {
      &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        min-width: 200px;
        text-align: left;

        .table_border {
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background-color: $border-color;
        }
      }
    }

    th,
    td {
      text-align: right;
    }

    th {
      vertical-align: bottom;
    }

    tr:nth-of-type(even) {
      --bs-table-accent-bg: white;
    }
  }
}

.dark-layout {
  .users_status_table {
    table tr {
      &:nth-of-type(even) {
        --bs-table-accent-bg: rgb(30, 41, 59);
      }
    }
  }
}