.app_container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  background-color: white;

  .app_header {
    padding: 32px;

    h1 {
      font-size: 32px;
      font-weight: 800;
    }

    .search .input-group {
      border-radius: 1.5rem;
    }
  }

  .app_content {
    height: 100%;

    table {
      tr {
        height: 60px;

        &:hover {
          background-color: $theme-bg-hover-color;
          cursor: pointer;
        }
      }

      th, td {
        vertical-align: middle;
      }
    }
  }
}

.dark-layout {
  .app_container {
    background-color: $theme-dark-paper-bg;

    .app_content {
      table {
        tr {
          .channel_badge.bg-primary {
            border: 1px solid $theme-dark-border-color;
          }

          &:hover {
            background-color: $theme-dark-bg-hover-color;
          }
        }
      }
    }
  }
}