.weekly-data-contacts-and-messages {
  border-radius: 16px;
  padding: 24px;

  .header {
    flex-direction: column;
    justify-content: space-between;

    .title {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    .tab-indicator {
      background-color: $theme-muted-text-light;
    }
  }

  @media screen and (min-width: 600px) {
    .header {
      flex-direction: row;
      align-items: flex-start;

      .title {
        margin-bottom: 0;
      }
    }
  }
}