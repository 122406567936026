.profile_app {
  $profile-bg-color: rgb(246, 249, 251);
  background-color: $profile-bg-color;

  .app_header {
    h1 {
      display: inline;
    }
  }

  .app_content {
    display: flex;
    padding: 0 calc(32px - 1rem) 32px 32px;

    .profile_summary {
      min-width: 320px;
      height: 100%;
      border-radius: 1.5rem;
      background-color: white;

      .rank_details {
        padding: 4rem 2rem;
        text-align: center;

        .rank_icon {
          padding-right: 2rem;
          padding-left: 2rem;
        }

        .rank_description {
          position: relative;
          top: 2rem;
        }
      }

      .rank_card {
        margin: 0.5rem;
        padding: 2rem;
        background-color: $profile-bg-color;
        border: 1px solid $border-color;
        border-radius: 1rem;
      }
    }

    .profile_details {
      width: 100%;
      padding-left: 32px;

      .stats {
        margin-left: -0.6rem;
        width: 100%;

        .summary_card {
          background-color: white;
          padding: 2rem;
          //border: 1px solid $border-color;
          border-radius: 1rem;
          min-width: 320px;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          margin-bottom: 1rem;
          flex-basis: calc(33% - 1rem);
          flex-grow: 1;

          .icon_container {
            display: inline-block;
            padding: 0.5rem;
            background-color: rgb(129, 140, 248);
            color: rgb(55, 48, 163);
            border-radius: 50%;
            margin-bottom: 1.5rem;

            svg {
              width: 36px;
            }
          }

          .value {
            font-size: 2rem;
            font-weight: bold;
          }
        }
      }

      .details_card {
        //border: 1px solid $border-color;
        background-color: white;
        border-radius: 1rem;
        padding: 1.5rem 2rem;
        margin-right: 1rem;
        margin-bottom: 1rem;

        .section_title {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 1rem;
        }

        .errors svg {
          width: 2.2rem;
        }
      }
    }
  }
}

.dark-layout .profile_app {
  .app_content {
    .profile_summary {
      background-color: $theme-dark-body-bg;

      .rank_card {
        background-color: $theme-dark-paper-bg;
        border-color: $theme-dark-border-color;
      }
    }

    .profile_details {
      .summary_card, .details_card {
        background-color: $theme-dark-body-bg;
      }

      .summary_card .value {
        color: rgba(white, 0.8);
      }
    }
  }
}