.area-chart-card {
  border-radius: 1rem;
  background-color: $theme-nested-card-bg;

  .header {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .title {
      .title-text {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.025em;
        font-weight: 500;
      }

      .subtitle-text {
        font-size: .857rem;
        font-weight: 400;
        color: #6c757d;
      }
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }

  .chart-container {
    min-height: 350px;

    svg {
      border-radius: 1rem;
    }
  }
}

.dark-layout {
  .area-chart-card {
    background-color: $theme-dark-nested-card-bg;
  }
}