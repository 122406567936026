// MUI Style fixes
.MuiOutlinedInput-root {
  fieldset {
    padding: 0 8px;
    border-color: $border-color;
    border-width: 1px;
    border-style: solid;

    legend {
      float: unset !important;
    }
  }
}

.MuiPagination-root {
  button {
    border-radius: 50%;
    margin: 0 3px;
  }
}

.MuiFormHelperText-root {
  margin-bottom: 0;
}

ul.MuiList-root {
  margin-bottom: 0;
  padding-left: 0;
}


// Dark Layout
.dark-layout {
  .MuiInputLabel-root {
    color: rgba($theme-dark-body-color, 0.6);
  }

  .MuiOutlinedInput-root {
    color: $theme-dark-body-color;

    .MuiSelect-icon {
      color: rgba($theme-dark-body-color, 0.54);
    }

    fieldset {
      border-color: $theme-dark-border-color;
    }

    &:hover fieldset {
      border-color: rgba($theme-dark-body-color, 0.6);
    }

    &.Mui-focused fieldset {
      border-color: $secondary;
    }
  }

  .MuiFormHelperText-root {
    color: rgba($theme-dark-body-color, 0.6);
  }

  ul.MuiList-root {
    color: $theme-dark-body-color;
    background-color: $theme-dark-body-bg;

    li {
      &:hover {
        background-color: $theme-dark-bg-hover-color;
      }

      &.Mui-selected {
        background-color: rgba(25, 118, 210, 0.28);
      }
    }
  }

  .MuiPagination-root {
    button {
      color: $theme-dark-body-color;
      border-color: $theme-dark-border-color;
    }
  }
}