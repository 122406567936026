.visitor-overview-card {
  border-radius: 1rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .06);

  .header {
    margin: 24px 24px 0;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .title {
      margin-bottom: 16px;

      p {
        margin: 0;
        line-height: 1.3;
      }

      .title-text {
        font-size: 18px;
        letter-spacing: -.025em;
        font-weight: 600;
        margin: 0;
        line-height: 24px;
      }

      .subtitle-text {
        font-size: .857rem;
        font-weight: 400;
        color: #6c757d;
      }
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;
      margin: 24px 24px 0;

      .title {
        margin-bottom: 0;
      }
    }
  }

  .chart-content {
    height: 24rem;

    svg {
      border-radius: 1rem;
    }
  }
}