.statistics {
  padding: 24px;
  border-radius: 16px;

  .header {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin-bottom: 16px;

    .title {
      p {
        margin: 0;
        line-height: 1.3;
      }

      .title-text {
        font-size: 18px;
        letter-spacing: -0.025em;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
      }

      .subtitle-text {
        font-size: .857rem;
        font-weight: 400;
        color: #6c757d;
      }
    }

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }
  }
}